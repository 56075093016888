import React from 'react';
import styled from 'styled-components';

/* Redux RTK ---------------------------*/
import { useSelector } from 'react-redux';

/* Font Icons ---------------------------*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faBuilding, faEnvelope } from '@fortawesome/free-solid-svg-icons';

/* Images ---------------------------*/
import ctmIconSrc from 'UI/common/ctm-logo-icon-only.png';

/* Components ---------------------------*/
import IconText from './IconText';

const Subtitle = () => {
	const {
		lookUp,
		portal,
		portalNotice: { displayNotice, noticeContent },
	} = useSelector(state => state.gaRouter);

	let lookUpTypeLabel = 'Email';
	let lookUpTypeIcon = faEnvelope;

	if (lookUp.type !== 'Email') {
		lookUpTypeLabel = 'Employee Id';
		lookUpTypeIcon = faIdCard;
	}

	return (
		<SubtitleStyled className='Subtitle'>
			{!displayNotice ? (
				<>
					<IconText
						Icon={() => <FontAwesomeIcon icon={faBuilding} />}
						label='Company'
						text={lookUp.companyId}
					/>
					<IconText
						Icon={() => <FontAwesomeIcon icon={lookUpTypeIcon} />}
						label={lookUpTypeLabel}
						text={lookUp.value}
					/>
					{portal.tenantName && (
						<IconText
							Icon={() => <img src={ctmIconSrc} />}
							label='CTM App'
							text={portal.tenantName}
						/>
					)}
				</>
			) : (
				<p>{noticeContent}</p>
			)}
		</SubtitleStyled>
	);
};

export default Subtitle;

const SubtitleStyled = styled.div`
    font-size: 12px;
    color: #888;
    padding: 15px;
    width: 60%;
    margin: 20px auto 0px;
    border: dashed 1px #e5e3e3;
`;
