import React from 'react';
import styled from 'styled-components';

/* Redux RTK ---------------------------*/
import { useSelector } from 'react-redux';

const Error = () => {

    const { enabled, title } = useSelector((state) => state.gaRouter.error);

    if (!enabled) {
        return '';
    }

    return (
        <ErrorStyled className="Error">
            {title}
        </ErrorStyled>
    );
};

export default Error;

const ErrorStyled = styled.div`
    width: 80%;
    background-color: #ffbe48;
    padding: 15px;
    font-size: 14px;
    color: #333;
    margin: 0px auto;
    border-radius: 5px;
    box-shadow: 0px 3px 7px rgb(0 0 0 / 10%);
    font-weight: bold;
`;
