import React from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import Title from './Title';
import Subtitle from './Subtitle';

const Action = () => {
    return (
        <ActionStyled className='Action'>
            <Title />
            <Subtitle />
        </ActionStyled>
    );
}

export default Action;

const ActionStyled = styled.div`
    margin: 40px 0px;
`;