import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_GLOBAL_ACCESS_API}`,
		prepareHeaders: async headers => {
			headers.set('Content-Type', 'application/json');
			headers.set(
				'Ocp-Apim-Subscription-Key',
				process.env.REACT_APP_GLOBAL_ACCESS_SUBSCRIPTION_KEY
			);
			return headers;
		},
	}),
	endpoints: builder => {
		return {
			/* Legacy */
			getProfiles: builder.query({
				query: () => `/GlobalProfile/GetProfiles`,
			}),
			getUserByRegion: builder.query({
				query: args => {
					return {
						url: '/find-user-region',
						params: { ...args },
					};
				},
			}),
			/* Sample API for dev and testing */
			getRedirectsByEnvironment: builder.query({
				query: () => '/get-redirects-by-environment/development',
			}),
			/* Legacy */
			postGetUserByEmail: builder.mutation({
				query: postBody => {
					return {
						method: 'POST',
						url: '/RegionSsoUrl/GetRegionSsoUrlByEmail',
						body: postBody,
					};
				},
			}),
			/* Legacy */
			postGetUserByEmployeeId: builder.mutation({
				query: postBody => {
					return {
						method: 'POST',
						url: '/RegionSsoUrl/GetRegionSsoUrlByEmployeeId',
						body: postBody,
					};
				},
			}),
		};
	},
});

export const { useGetProfilesQuery, useLazyGetUserByRegionQuery } = apiSlice;
