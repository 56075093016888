import React from 'react';
import styled from 'styled-components';

/* Redux RTK ---------------------------*/
import { useSelector } from 'react-redux';

const Title = () => {
    const { title } = useSelector((state) => state.gaRouter.status);

    return (
        <TitleStyled className='Title'>
            { title }
        </TitleStyled>
    );
}

export default Title;

const TitleStyled = styled.h1`
    font-size: 24px;
    color: #73ad69;
    margin: 0px;
`;