import styled from 'styled-components';
import CTMLogoSrc from 'UI/common/ctm-logo-long.png';

const CTMLogo = () => {
    return (
        <CTMLogoStyled className='CTMLogo'>
            <img src={CTMLogoSrc} />
        </CTMLogoStyled>
    );
}

export default CTMLogo;

const CTMLogoStyled = styled.div`
    img {
        width: 100%;
        max-width: 150px;
    }
`;