import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import reduxStore from 'Store';
import { Provider as ReduxProvider } from 'react-redux';
import Auth0ProviderWithRedirect from './UI/common/Auth0/AuthProviderWithRedirect';
import UI from 'UI/UI.jsx';

const App = () => {
	return (
		<ReduxProvider store={reduxStore}>
			<Router>
				<Auth0ProviderWithRedirect>
					<UI />
				</Auth0ProviderWithRedirect>
			</Router>
		</ReduxProvider>
	);
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
