import React from 'react';
import styled from 'styled-components';

/* Redux RTK ---------------------------*/
import { useGetProfilesQuery } from 'Store/apiSlice';

/* Components ---------------------------*/
import AccountList from './AccountList';

const TestAccounts = () => {
	const { data: accounts, isLoading } = useGetProfilesQuery();

	return (
		<TestAccountsStyled className='TestAccounts'>
			<h1>Global Access (Model 2: Look up and Route)</h1>
			<h2>Branch: master</h2>
			<h3>Test Accounts</h3>
			{isLoading || !accounts ? (
				'Loading...'
			) : (
				<AccountList accounts={accounts} />
			)}
		</TestAccountsStyled>
	);
};

export default TestAccounts;

const TestAccountsStyled = styled.div`
    background-color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    max-width: 700px;
    min-height: 250px;
    padding: 20px;
    margin: 100px auto;
    border-radius: 5px;
    box-shadow: 0px 3px 7px rgb(0 0 0 / 10%);

    h1 {
        margin: 0px 0px 10px;
    }
`;
