import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import {
	NODE_ENV,
	REACT_APP_CURRENT_ENV,
	REACT_APP_CUSTOM_NAMESPACE,
} from 'Common/environment';

import { useSelector, useDispatch } from 'react-redux';
import {
	setLookup,
	setPortal,
	setStatus,
	setError,
	setPortalNotice,
	setInterstitial,
} from 'Store/gaRouterSlice';
import { useLazyGetUserByRegionQuery } from 'Store/apiSlice';

import { GetPortalRedirect, MapRegion } from '../../common/configs/redirects';

import CTMLogo from './CTMLogo';
import Action from './Action';
import Status from './Status';

const notices = {
	errors: {
		auth: 'You are not authorized to access CTM portals.  Please check your credentials.',
		nomatch: "Unable to locate this user's CTM Portal.",
		mismatch: 'Unable to determine the correct CTM Portal.',
		missing:
			'You appear to be missing some credentials.  Please check your username or email.',
	},
};

const RegionPortalRedirect = () => {
	const dispatch = useDispatch();
	let { user, isAuthenticated } = useAuth0();

	// prettier-ignore
	const { 
		companyId,
		email, 
		regionConfigured, 
		customRedirects,
		portalMessage
	} = user[REACT_APP_CUSTOM_NAMESPACE];

	const isLowerEnv = REACT_APP_CURRENT_ENV !== 'prod';

	if (isLowerEnv) {
		console.log(`*** Current environment is: ${NODE_ENV} ***`);
	}

	const [getUserByRegion] = useLazyGetUserByRegionQuery();

	const {
		portal: { redirectUrl },
		portalNotice: { noticeContent, noticeHeading, noticeAlert },
	} = useSelector(state => state.gaRouter);

	const lookUpType = 'Email';
	const lookUpValue = email;

	const lookUpMessage = `Company Id: ${companyId} • ${lookUpType}: ${lookUpValue}`;

	const getUserRegion = async () => {
		let redirect = '';
		let userRegion = '';

		/* 
			No point in going further if 
			either email or companyId is missing 
		*/
		if (!email || !companyId) {
			dispatch(
				setInterstitial({
					display: false,
				})
			);

			return dispatch(
				setError({
					enabled: true,
					title: notices.errors.missing,
				})
			);
		}

		/* Update userRegion value */
		if (!regionConfigured) {
			// TODO: create logging component
			if (isLowerEnv) {
				console.log('***Region NOT configured! Calling API... ***');
			}
			const {
				data: { region },
			} = await getUserByRegion({
				username: email,
				companyId,
			});

			userRegion = region;
		} else {
			// TODO: create logging component
			if (isLowerEnv) {
				console.log('***Region IS configured! Getting redirect... ***');
			}
			userRegion = MapRegion(regionConfigured);
		}

		/* Stop script if region is false - no way to get redirect */
		if (!userRegion) {
			dispatch(
				setInterstitial({
					display: false,
				})
			);

			return dispatch(
				setError({
					enabled: true,
					title: notices.errors.nomatch,
				})
			);
		}
		if (isLowerEnv) {
			console.log(
				`*** REGION is ${userRegion} BEFORE Custom redirect condition ***`
			);
		}

		/* Stub for portal notices */
		if (portalMessage) {
			dispatch(
				setPortalNotice({
					displayNotice: true,
					noticeContent: noticeContent,
				})
			);

			dispatch(
				setInterstitial({
					display: false,
				})
			);

			return dispatch(
				setStatus({
					title: noticeAlert,
					subtitle: noticeHeading,
				})
			);
		}

		redirect = GetPortalRedirect(userRegion);

		if (!redirect) {
			dispatch(
				setInterstitial({
					display: false,
				})
			);

			return dispatch(
				setError({
					enabled: true,
					title: notices.errors.mismatch,
				})
			);
		}

		/* Creating stub for custom redirects */
		let subtitle = `${lookUpMessage} -> ${companyId}`;
		let tenantName = companyId;

		if (customRedirects) {
			redirect = 'https://www.samltool.com/index.php';
			subtitle = 'Custom Redirect Request';
			tenantName = 'SAML Tools';
		}

		dispatch(
			setStatus({
				title: 'Redirecting',
				subtitle,
			})
		);
		dispatch(
			setPortal({
				redirectUrl: redirect,
				tenantName,
			})
		);
	};

	useEffect(() => {
		if (!user || !isAuthenticated) {
			dispatch(
				setInterstitial({
					display: false,
				})
			);

			return dispatch(
				setError({
					enabled: true,
					title: notices.errors.auth,
				})
			);
		}

		dispatch(
			setInterstitial({
				display: true,
			})
		);

		dispatch(setLookup({ companyId, lookUpType, lookUpValue }));
		dispatch(
			setStatus({ title: 'Locating CTM Portal', subtitle: lookUpMessage })
		);
		setTimeout(getUserRegion, 3000);
	}, []);

	useEffect(() => {
		if (redirectUrl) {
			setTimeout(() => {
				document.location.href = redirectUrl;
			}, 3000);
		}
	}, [redirectUrl]);

	return (
		<RegionPortalRedirectStyled className='RegionPortalRedirect'>
			<CTMLogo />
			<Action />
			<Status />
		</RegionPortalRedirectStyled>
	);
};

export default RegionPortalRedirect;

const RegionPortalRedirectStyled = styled.div``;
