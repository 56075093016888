import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './common/Auth0/ProtectedRoute';
import TestAccounts from './TestAccounts/TestAccounts';
import Login from './common/Login';

/* GA Router ---------------------------*/
import GaRouterLayout from './GaRouter/GaRouterLayout.jsx';
import Callback from './GaRouter/Callback';

const UI = () => {
	return (
		<Routes>
			<Route path='/' element={<Login />} />
			<Route
				path='/accounts'
				element={<ProtectedRoute component={TestAccounts} />}
			/>
			<Route element={<GaRouterLayout />}>
				<Route
					path='/callback'
					element={<ProtectedRoute component={Callback} />}
				/>
			</Route>
		</Routes>
	);
};

export default UI;
