import React from 'react';
import styled from 'styled-components';

const IconText = ({Icon, label='Sample Label', text='Sample Text'}) => {


    return (
        <IconTextStyled className='IconText'>
            <div className='icon'><Icon /></div>
            <div className='text'><b>{label}:</b>{text}</div>
        </IconTextStyled>
    );
}

export default IconText;

const IconTextStyled = styled.div`

    display: flex;
    margin: 5px 0px;

    .icon {
        /* outline: solid 1px red; */
        width: 20px;
        margin-right: 5px;

        img {
            max-width: 80%;
        }
    }

    .text {
        /* outline: solid 1px red; */
        flex: 1;
        text-align: left;
        b {
            margin-right: 3px;
        }
    }
`;