import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

const Account = ({ account }) => {
    const { name, companyId, employeeId, email } = account;

    return (
        <AccountStyled className="Account">
            <td>{name}</td>
            <td>{email && <Link to={`/cmpId/${companyId}/email/${email}`}>{email}</Link>}</td>
            <td>
                {employeeId && (
                    <Link to={`/cmpId/${companyId}/empId/${employeeId}`}>{employeeId}</Link>
                )}
            </td>
        </AccountStyled>
    );
};

export default Account;

const AccountStyled = styled.tr``;
