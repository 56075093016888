// https://redux.js.org/introduction/getting-started
import { configureStore } from '@reduxjs/toolkit';

import gaRouterSlice from './gaRouterSlice.js';
import { apiSlice } from './apiSlice.js';

export default configureStore({
    devTools: {
        name: 'Global Access Router',
    },
    reducer: {
        gaRouter: gaRouterSlice,
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleWare) => {
        return getDefaultMiddleWare().concat(apiSlice.middleware)
    }
})