import React from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import Spinner from './Spinner';
import Error from './Error';

const Status = () => {
    return (
        <StatusStyled className='Status'>
            <Spinner />
            <Error />
        </StatusStyled>
    );
}

export default Status;

const StatusStyled = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
`;