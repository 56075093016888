import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import {
	REACT_APP_AUTH0_CALLBACK_URL,
	REACT_APP_AUTH0_CLIENT_ID,
	REACT_APP_AUTH0_DOMAIN,
} from 'Common/environment';

const Auth0ProviderWithRedirect = ({ children }) => {
	const navigate = useNavigate();
	const onRedirectCallback = appState => {
		navigate((appState && appState.returnTo) || window.location.pathname);
	};

	if (
		!(
			REACT_APP_AUTH0_DOMAIN &&
			REACT_APP_AUTH0_CLIENT_ID &&
			REACT_APP_AUTH0_CALLBACK_URL
		)
	) {
		return null;
	}

	return (
		<Auth0Provider
			domain={REACT_APP_AUTH0_DOMAIN}
			clientId={REACT_APP_AUTH0_CLIENT_ID}
			authorizationParams={{
				redirect_uri: REACT_APP_AUTH0_CALLBACK_URL,
			}}
			useRefreshToken
			cacheLocation='localstorage'
		>
			{children}
		</Auth0Provider>
	);
};

export default Auth0ProviderWithRedirect;
