import { createSlice } from '@reduxjs/toolkit';

// Redux Slice
export const gaRouterSlice = createSlice({
	name: 'courses',
	initialState: {
		lookUp: {
			companyId: 1,
			type: 'Email',
			value: 'user@email.com',
		},
		portal: {
			redirectUrl: '',
			tenantName: '',
		},
		portalNotice: {
			displayNotice: false,
			noticeAlert: 'Portal Availability Notice',
			noticeHeading: 'Portal Experience Coming Soon!',
			noticeContent:
				'The portal you trying to reach is not yet available. Please contact your travel manager or travel arranger for additional details.',
		},
		error: {
			enabled: false,
			title: 'Error',
			message: `Unable to locate this user's CTM Portal.`,
		},
		interstitial: {
			display: false,
		},
		status: {
			title: 'Locating CTM Portal',
			subtitle: 'Goodbye',
		},
	},
	reducers: {
		setLookup: (state, action) => {
			const { companyId, lookUpType, lookUpValue } = action.payload;

			state.lookUp.companyId = companyId;
			state.lookUp.type = lookUpType;
			state.lookUp.value = lookUpValue;
		},
		setPortal: (state, action) => {
			const { redirectUrl, tenantName } = action.payload;

			state.portal.redirectUrl = redirectUrl;
			state.portal.tenantName = tenantName;
		},
		setPortalNotice: (state, action) => {
			const { displayNotice, noticeContent } = action.payload;

			state.portalNotice.displayNotice = displayNotice;
			state.portalNotice.noticeContent = noticeContent;
		},
		setError: (state, action) => {
			const { enabled, title } = action.payload;

			state.error.enabled = enabled;
			state.error.title = title;
		},
		setInterstitial: (state, action) => {
			const { display } = action.payload;

			state.interstitial.display = display;
		},
		setStatus: (state, action) => {
			const { title, subtitle } = action.payload;

			state.status.title = title;
			state.status.subtitle = subtitle;
		},
	},
});

// Slice Action Creators
export const {
	setLookup,
	setPortal,
	setError,
	setStatus,
	setPortalNotice,
	setInterstitial,
} = gaRouterSlice.actions;

export default gaRouterSlice.reducer;
