import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Login = () => {
	const { loginWithRedirect, isAuthenticated } = useAuth0();
	let navigate = useNavigate();

	useEffect(() => {
		if (!isAuthenticated) {
			const params = new URLSearchParams(location.search);
			const connection = params.get('connection');

			console.log('params', params);
			console.log('connection', connection);
			if (!connection) {
				return loginWithRedirect();
			}

			loginWithRedirect({ authorizationParams: { connection } });
		} else {
			navigate('/');
		}
	}, []);

	return <LoginStyled className='Login'>Login Connection Loading...</LoginStyled>;
};

export default Login;

const LoginStyled = styled.div``;
