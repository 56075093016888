import React from 'react';
import styled from 'styled-components';

import Account from './Account';

const AccountList = ({accounts}) => {
    return (
        <AccountListStyled className='AccountList'>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>EmployeeId</th>
                </tr>
            </thead>
            <tbody>
                {
                    accounts.map((account, idx) => {
                        return <Account key={ idx } account={ account } />;
                    })
                }
            </tbody>
        </AccountListStyled>
    );
}

export default AccountList;

const AccountListStyled = styled.table`
    border-collapse: collapse;
    border: solid 1px #333;
    width: 100%;

    th, td {
        border: solid 1px #333;
        padding: 5px;
    }

`;