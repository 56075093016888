import envConfigs from './static-data';
import { REACT_APP_CURRENT_ENV } from 'Common/environment';

const setConnection = () => {
	const availableEnvs = Object.keys(envConfigs);

	if (!availableEnvs.includes(REACT_APP_CURRENT_ENV)) {
		console.warn(
			`The environment ${REACT_APP_CURRENT_ENV} does not exist in configs`
		);
		return '';
	}

	return `GA-CTM-${REACT_APP_CURRENT_ENV.toUpperCase()}-SAML-Connection`;
};

/**
 * Region mapping arrays
 *
 * Enables clients not in HR feeds
 * to send region in SAML assertions and
 * we map to our configs
 *
 * TODO:
 * * expand envConfigs to contain separate objects: redirects &  notices
 * * Convert these arrays to key/value pairs
 *   * Key === Region
 *   * Value === comma separated mapping values
 */
const noram = ['USA', 'AMERICAS', 'NORAM'];
const emea = ['EUROPE', 'UK', 'EMEA'];
const apac = ['ASIA', 'APAC', 'ASZ'];
const anz = ['ANZ', 'NZAU', 'AUS'];

/**
 * @param region {string}
 * @param custom {boolean}
 *
 * Param custom used for quick implementation of
 * custom redirects for specific client.
 *
 * Configs for this feature shoujld be sent in
 * assertions or retrieved from an API request
 * intead
 *
 * ATTOW, custom redirects is not actively in use.
 *
 * mhirschmann 2024-02-18
 */
export const GetPortalRedirect = (region, custom = false) => {
	const samlConnection = setConnection();

	if (!samlConnection) {
		return samlConnection;
	}

	const { portal, redirect } = envConfigs[REACT_APP_CURRENT_ENV][region];

	let { path } = redirect;

	// prettier-ignore
	if (custom && ('custom' in redirect)) {
		console.log('*** GetPortalRedirect: Using custom redirect... ***')
		const { path } = redirect.custom;
		return `${portal}/${path}`;
	}

	return `${portal}/${path}?connection=${samlConnection}`;
};

export const MapRegion = assertion => {
	let region;

	switch (true) {
		case noram.includes(assertion):
			region = 'USA';
			break;
		case emea.includes(assertion):
			region = 'EUR';
			break;
		case apac.includes(assertion):
			region = 'ASIA';
			break;
		case anz.includes(assertion):
			region = 'ANZ';
			break;
		default:
			region = '';
			break;
	}

	return region;
};
