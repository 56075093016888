import React from 'react';
import { default as PortalRedirect } from './PortalRedirect';

const Callback = () => {
	return (
		<>
			<PortalRedirect />
		</>
	);
};

export default Callback;
