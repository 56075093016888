import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const GaRouterLayout = () => {
    return (
        <GaRouterLayoutStyled className='GaRouterLayout'>
            <Outlet />
        </GaRouterLayoutStyled>
    );
}

export default GaRouterLayout;

const GaRouterLayoutStyled = styled.main`
    background-color: #fff;

    font-family: Arial, Helvetica, sans-serif;

    text-align: center;
    max-width: 500px;
    min-height: 250px;
    padding: 50px 20px;
    margin: 100px auto;
    border-radius: 5px;

    box-shadow: 0px 3px 7px rgb(0 0 0 / 10%);

    .react-loading {
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;