import React from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';

/* Redux RTK ---------------------------*/
import { useSelector } from 'react-redux';

const Spinner = () => {
	const { display } = useSelector(state => state.gaRouter.interstitial);

	if (!display) {
		return null;
	}
	return (
		<SpinnerStyled className='Spinner'>
			<ReactLoading type='spin' color='#000' />
		</SpinnerStyled>
	);
};

export default Spinner;

const SpinnerStyled = styled.div`
    width: 64px;
    margin: 40px auto;
`;
