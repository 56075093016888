const envConfigs = {
	dev: {
		connection: 'GA-CTM-DEV-SAML-Connection',
		USA: {
			tenant: 'https://ctm-corp-na-dev.auth0.com',
			portal: 'https://elysium-dev.ctmna-apps.net',
			redirect: {
				path: 'login',
			},
		},
		EUR: {
			tenant: 'https://ctmuk-dev.eu.auth0.com',
			portal: 'https://test.ctmportal.co.uk',
			redirect: {
				path: '',
				custom: {
					path: 'login',
				},
			},
		},
		ASIA: {
			tenant: 'https://ctmasia.auth0.com',
			portal: 'https://ctmasia.auth0.com', // Asia requires tenant initiated, duplication intentional
			redirect: {
				path: 'authorize?scope=openid email profile&response_type=token id_token&client_id=NWlTe4lm7fBBajtNq8kFTljGf22U4DHY&connection=GA-CTM-PROD-SAML-Connection&redirect_uri=https://portal.travelctm.asia/callback&nonce=nonce',
			},
			// prettier-ignore
		},
		ANZ: {
			tenant: 'https://travelctm-au-development.au.auth0.com',
			portal: 'https://portal-dev.ctmdevelopment.com',
			redirect: {
				path: '',
			},
			// prettier-ignore
		},
	},
	stage: {
		// TODO: fix up configs for stage just like production & development
		connection: 'GA-CTM-STAGE-SAML-Connection',
		USA: {
			tenant: 'ctm-corp-na-stage.auth0.com',
			portal: 'https://elysium-stage.ctmna-apps.net',
			redirect: {
				path: 'login',
			},
			// prettier-ignore
		},
		EUR: {
			tenant: 'https://ctmuk.eu.auth0.com',
			portal: 'https://ctmportal.co.uk',
			redirect: {
				path: '',
				custom: {
					path: '',
				},
			},
			// prettier-ignore
		},
		ASIA: {},
		ANZ: {
			// Setting ANZ prod only because we have an active connection there.
			tenant: 'https://travelctm-au-production.au.auth0.com',
			portal: 'https://www.ctmsmart.com.au',
			redirect: {
				path: '',
			},
		},
	},
	prod: {
		connection: 'GA-CTM-PROD-SAML-Connection',
		USA: {
			tenant: 'ctm-corp-na-prod.auth0.com',
			portal: 'https://us.ctmsmart.com',
			redirect: {
				path: 'login',
			},
		},
		EUR: {
			tenant: 'https://ctmuk.eu.auth0.com',
			portal: 'https://ctmportal.co.uk',
			redirect: {
				path: '',
				custom: {
					path: '',
				},
			},
			// prettier-ignore
		},
		ASIA: {
			tenant: 'https://ctmasia.auth0.com',
			portal: 'https://ctmasia.auth0.com', // Asia requires tenant initiated, duplication intentional
			redirect: {
				path: 'authorize?scope=openid email profile&response_type=token id_token&client_id=NWlTe4lm7fBBajtNq8kFTljGf22U4DHY&connection=GA-CTM-PROD-SAML-Connection&redirect_uri=https://portal.travelctm.asia/callback&nonce=nonce',
			},
			// prettier-ignore
		},
		ANZ: {
			tenant: 'https://travelctm-au-production.au.auth0.com',
			portal: 'https://www.ctmsmart.com.au',
			redirect: {
				path: '',
			},
			// prettier-ignore
		},
	},
};

export default envConfigs;
